import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { FiMenu, FiX } from "react-icons/fi/";

// Components & Utils
import Link from '../utils/Link'
import Button from '../components/Button'

// STYLE
const NavbarContainer = styled.nav`
  background: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 24px 0 24px 0;
  z-index: 1000;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }


  .brand {
    img {
      max-height: 24px;
    }

    &:hover, &:active {
      filter: none;
    }
  }

  .nav-links {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2em;

    li {
      a {
        color: var(--primary-font-color);

        &:hover, &:active {
          filter: brightness(150px);
        }
      }
    }
  }

  .mobile-nav-toggle {
    display: none;
    cursor: pointer;
  }

  .mobile-nav {
    display: none;
    background-color: #0F0D1Be6;
    position: absolute;
    top: 100%;
    left: 20px;
    right: 20px;
    border: var(--border-width) solid;
    border-radius: var(--border-radius);
    border-color: var(--border-color);
    backdrop-filter: blur(8px);
    overflow: hidden;

    ul {
      list-style: none;
      margin: 0;


      li {
        position: relative;
        height: 60px;
        width: 100%;

        a {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;

          &:hover {
            background-color: #131120e6;
          }
        }
      }
    }
  }

  @media (max-width: 1100px) {
    .nav-links {
      display: none;
    }

    .main-cta {
      display: none;
    }

    .mobile-nav-toggle {
      display: initial;
    }

    .mobile-nav {
      display: initial;
    }
  }
`

const Navbar = () => {
  const { prismicNavigation } = useStaticQuery(query)
  const data = prismicNavigation.data
  const navLinks = data.nav_links

  const [isMobileNavActive, setIsMobileNavActive] = useState(false)

  const toggleMobileNav = (e) => {
    e.preventDefault();
    setIsMobileNavActive(!isMobileNavActive);
  }

  return (
    <NavbarContainer role="navigation" aria-label="Main">
      <div className="container">
        <Link className="brand" to="/"><GatsbyImage image={data.brand_icon.localFile.childImageSharp.gatsbyImageData} alt={data.brand_icon.alt} /></Link>
        <ul className="nav-links">
          {navLinks.map((link, index) => (
            <li key={`nav-link-${index}`}><Link to={link.link_url.url}>{link.link_text}</Link></li>
          ))}
        </ul>
        { data.button_url.url && <Button type="primary" className="main-cta" to={data.button_url.url} target={data.button_url.target}>{data.button_text}</Button> }
        <Button type="primary" className="mobile-nav-toggle" onClick={toggleMobileNav}>{isMobileNavActive ? <FiX /> : <FiMenu />}</Button>
      </div>
      { isMobileNavActive && (
        <div className='mobile-nav'>
          <ul className="mobile-nav-links">
            {navLinks.map((link, index) => (
              <li key={`nav-link-${index}`}><Link to={link.link_url.url}>{link.link_text}</Link></li>
            ))}
          </ul>
        </div>
      )}
    </NavbarContainer>
  )
}

export default Navbar

const query = graphql`
  query Navigation {
    prismicNavigation {
      data {
        brand_icon {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        nav_links {
          link_text
          link_url {
            url
          }
        }
        button_text
        button_url {
          url
          target
        }
      }
    }
  }
`