import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
// import { linkResolver } from './linkResolver'

const Link = ({ children, to, target, activeClassName, partiallyActive, ...other }) => {

  // Verification de la propriété "Target" définie lors de l'ajout d'un lien dans Prismic.
  // S'il s'agit d'un lien interne, la propriété devrait être égale à "null" car non définie.

  // Si lien interne, on utilise le composant Link fourni par Gatsby afin de précharger les ressources
  if (!target) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }

  // Dans le cas contraire, on utilise un lien classique
  return (
    <a href={to} target={target} {...other}>
      {children}
    </a>
  )
}

export default Link