import * as React from "react"
import { createGlobalStyle } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { RichText } from 'prismic-reactjs'

// Components & Utils
import Seo from '../utils/Seo'
import LinkPrismic from '../utils/LinkPrismic'
import GlobalMessage from './GlobalMessage'
import Navbar from './Navbar'
import Footer from './Footer'

// STYLE
const GlobalStyle = createGlobalStyle`
  :root {
    --container: calc(100% - 40px);
    --accent-color: ${props => (props.theme.accent || "#1839CD")};
    --pages-background-color: ${props => (props.theme.background || "#0B0917")};
    --headings-font-color: ${props => (props.theme.headingsFont || "#EFEFEF")};
    --headings-font-family: "Syncopate";
    --text-font-family: "Montserrat";
    --primary-font-color: ${props => (props.theme.primaryFont || "#EFEFEF")};
    --medium-font-size: ${props => (props.theme.mediumFontSize || 16)}px;
    --secondary-font-color: ${props => (props.theme.secondaryFont || "#A0A0A0")};
    --small-font-size: ${props => (props.theme.smallFontSize|| 12)}px;
    --cards-bg: ${props => (props.theme.cards || "#2C2A3E")};
    --primary-btn-bg: ${props => (props.theme.accent || "#1839CD")};
    --secondary-btn-bg: ${props => (props.theme.cards || "#2C2A3E")}52;
    --border-color: ${props => (props.theme.borders || "#2C2A3E")};
    --border-radius: ${props => (props.theme.bordersRadius || 4)}px;
    --border-width: ${props => (props.theme.bordersWidth || 1)}px;
    --section-padding: 60px;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    background-color: var(--pages-background-color);
    color: var(--secondary-font-color);
    font-size: var(--medium-font-size);
    font-family: var(--text-font-family), sans-serif;
    font-weight: 600;
  }

  #page {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  #maintenance-page {
    padding-top: 60px;
    padding-bottom: 60px;

    h1 {
      margin-bottom: 0.6em;
    }

    p {
      margin-bottom: 0.2em;
    }
  }

  main {
    flex-grow: 1;
    position: relative;
  }

  h1 {
    color: var(--headings-font-color);
    font-size: ${props => (props.theme.h1Size || 48)}px;
    font-family: var(--headings-font-family), sans-serif;
    font-weight: 700;
  }

  h2 {
    color: var(--headings-font-color);
    font-size: ${props => (props.theme.h2Size || 36)}px;
    font-family: var(--headings-font-family), sans-serif;
    font-weight: 700;
  }

  h3 {
    color: var(--headings-font-color);
    font-size: ${props => (props.theme.h3Size || 24)}px;
    font-family: var(--headings-font-family), sans-serif;
    font-weight: 700;
  }

  h4 {
    color: var(--headings-font-color);
    font-size: ${props => (props.theme.h4Size || 20)}px;
    font-family: var(--text-font-family), sans-serif;
  }

  h5 {
    color: var(--headings-font-color);
    font-size: ${props => (props.theme.h5Size || 18)}px;
    font-family: var(--text-font-family), sans-serif;
  }

  h6 {
    color: var(--headings-font-color);
    font-size: ${props => (props.theme.h6Size || 16)}px;
    font-family: var(--text-font-family), sans-serif;
  }

  a {
    text-decoration: none;
    color: ${props => (props.theme.accent || "#1839CD")};
    transition: all .3s ease-in-out;

    &:hover, &:active {
      transition: all .3s ease-in-out;
      filter: brightness(120%);
    }
  }

  section {
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
  }

  .container{
    width: 100%;
    padding-left: calc(50% - var(--container) / 2);
    padding-right: calc(50% - var(--container) / 2);
  }

  @media only screen and (min-width: 1340px) {
    :root {
      --container: 1300px;
    }
  }
`

// MARKUP
const Layout = ({ children }) => {

  // DONNEES
  // Récupération des données depuis Prismic
  const data = useStaticQuery(graphql`
    query SiteConfigQuery {
      prismicSiteConfig {
        data {
          accent_color
          bg_color
          borders_color
          borders_radius
          borders_width
          cards_background
          h1_font_size
          h2_font_size
          h3_font_size
          h4_font_size
          h5_font_size
          h6_font_size
          headings_font_color
          medium_font_size
          primary_font_color
          secondary_font_color
          site_meta_description
          site_title
          small_font_size
          is_global_msg_enabled
          is_maintenance_mode_enabled
          maintenance_page_content {
            raw
          }
        }
      }
    }  
  `)

  // Style: Thème
  const theme = {
    accent: data.prismicSiteConfig.data.accent_color,
    background: data.prismicSiteConfig.data.bg_color,
    borders: data.prismicSiteConfig.data.borders_color,
    bordersRadius: data.prismicSiteConfig.data.borders_radius,
    bordersWidth: data.prismicSiteConfig.data.borders_width,
    cards: data.prismicSiteConfig.data.cards_background,
    headingsFont: data.prismicSiteConfig.data.headings_font_color,
    primaryFont: data.prismicSiteConfig.data.primary_font_color,
    secondaryFont: data.prismicSiteConfig.data.secondary_font_color,
    h1Size: data.prismicSiteConfig.data.h1_font_size,
    h2Size: data.prismicSiteConfig.data.h2_font_size,
    h3Size: data.prismicSiteConfig.data.h3_font_size,
    h4Size: data.prismicSiteConfig.data.h4_font_size,
    h5Size: data.prismicSiteConfig.data.h5_font_size,
    h6Size: data.prismicSiteConfig.data.h6_font_size,
    headingsFontFamily: data.prismicSiteConfig.data.headings_font_family,
    mediumFontSize: data.prismicSiteConfig.data.medium_font_size,
    smallFontSize: data.prismicSiteConfig.data.small_font_size,
    textFontFamily: data.prismicSiteConfig.data.text_font_family
  }

  // Config: Cas spécifiques
  const layoutExceptions = {
    isMaintenanceModeEnabled: data.prismicSiteConfig.data.is_maintenance_mode_enabled,
    maintenanceModeContent: data.prismicSiteConfig.data.maintenance_page_content.raw,
    isGlobalMsgEnabled: data.prismicSiteConfig.data.is_global_msg_enabled,
  }

  // Si le site est en maintenance, on affiche la page dédiée
  if (layoutExceptions.isMaintenanceModeEnabled) {
    return (
      <div id="maintenance-page" className="container">
        <GlobalStyle theme={theme} />
        <RichText render={layoutExceptions.maintenanceModeContent} serializeHyperlink={LinkPrismic} />
      </div>
    )
  }


  return (
    <>
      <Seo />
      { layoutExceptions.isGlobalMsgEnabled && <GlobalMessage /> }
      <div id="page">
        <GlobalStyle theme={theme} />
        <Navbar />
        <main> {children} </main>
        <Footer />
      </div>
    </>
  )
}

export default Layout