import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import styled from "styled-components"

// Icons
import { FiExternalLink } from "react-icons/fi/";
import { SiFacebook } from "react-icons/si/";

// Components & Utils
import Link from '../utils/Link'

// STYLE
const FooterContainer = styled.footer`
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: ${props => (props.bgColor || "#080711")};
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
  font-size: var(--small-font-size);

  .copyright {
    color: var(--primary-font-color);
  }

  .footer-links {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 1em;
    flex-grow: 1;

    li {
      display: flex;
      gap: 0.1em;

      a {
        color: var(--secondary-font-color);
      }
    }
  }

  .social-btn {
    font-size: 2em;
    color: var(--primary-font-color);
  }
`

const Footer = () => {
  const { prismicFooter } = useStaticQuery(query)

  const data = prismicFooter.data
  const footerLinks = data.footer_nav

  return (
    <FooterContainer className={`container`} aria-label="Footer" bgColor={data.background_color}>
      <div className="copyright">{data.copyright_notice.text}</div>
      <ul className="footer-links">
        {footerLinks.map((link, index) => (
          <li key={`footer-link-${index}`}>
            <Link to={link.link_url.url} target={link.link_url.target}>
              <span>{link.link_text}</span>
              {link.link_url.target && <FiExternalLink />}
            </Link>
          </li>
        ))}
      </ul>
      { data.facebook_url.url && <Link className="social-btn" to={data.facebook_url.url} target={data.facebook_url.target}><SiFacebook /></Link> }
    </FooterContainer>
  )
}

export default Footer

const query = graphql`
  query Footer {
    prismicFooter {
      data {
        background_color
        copyright_notice {
          text
        }
        footer_nav {
          link_url {
            url
            target
          }
          link_text
        }
        facebook_url {
          url
          target
        }
      }
    }
  }
`