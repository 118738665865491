import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
const Seo = ({ pageTitle, pageDescription, pageImage }) => {
  // Récupériation des données SEO via Prismic
  const { prismicSiteConfig } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultDescription
  } = prismicSiteConfig.data

  const { favicon } = prismicSiteConfig.data.site_favicon.localFile
  const { defaultImage } = prismicSiteConfig.data.site_og_image.localFile

  const seo = {
    title: `${pageTitle ? `${pageTitle} | ` : ""}${defaultTitle}`,
    description: pageDescription || defaultDescription,
    favicon: favicon,
    image: pageImage || defaultImage,
  }

  return (
    <Helmet title={seo.title}>

      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && <meta name="description" content={seo.description} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      
      {seo.favicon && <link rel="icon" ype="image/png" href={seo.favicon} sizes="48x48" />}

      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

    </Helmet>
  )
}

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

Seo.defaultProps = {
  title: null,
  description: null
}

const query = graphql`
  query SEO {
    prismicSiteConfig {
      data {
        defaultTitle: site_title
        defaultDescription: site_meta_description
        site_favicon {
          localFile {
            favicon: publicURL
          }
        }
        site_og_image {
          localFile {
            defaultImage: publicURL
          }
        }
      }
    }
  }
`