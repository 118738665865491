import * as React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// Components & Utils
import Link from '../utils/Link'

// STYLE
const GlobalMessageDiv = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  background-color: ${props => (props.styling.backgroundColor || "var(--accent-color)")};
  color: ${props => (props.styling.textColor || "var(--primary-font-color)")};

  a {
    color: ${props => (props.styling.linkColor || "var(--secondary-font-color)")};;
  }
`

// MARKUP
const GlobalMessage = () => {

  // DONNEES
  // Récupération des données depuis Prismic
  const data = useStaticQuery(graphql`
    query GlobalMessageQuery {
      prismicSiteConfig {
        data {
          global_msg_bg_color
          global_msg_text
          global_msg_text_color
          global_msg_link_label
          global_msg_link_color
          global_msg_link_url {
            url
            target
          }
        }
      }
    }  
  `)

  // Style via Prismic
  const styling = {
    backgroundColor: data.prismicSiteConfig.data.global_msg_bg_color,
    textColor: data.prismicSiteConfig.data.global_msg_text_color,
    linkColor: data.prismicSiteConfig.data.global_msg_link_color,
  }

  // Simplifications des données récupérées
  const linkLabel = data.prismicSiteConfig.data.global_msg_link_label;
  const linkUrl = data.prismicSiteConfig.data.global_msg_link_url.url;
  const linkTarget = data.prismicSiteConfig.data.global_msg_link_url.target;

  return (
    <GlobalMessageDiv id="msg-global" className="container" styling={styling}>
      <p>{data.prismicSiteConfig.data.global_msg_text}</p>
      { (linkLabel && linkUrl) && <Link to={linkUrl} target={linkTarget ? linkTarget : "_self"}> {linkLabel} </Link> }
    </GlobalMessageDiv>
  )
}

export default GlobalMessage