import * as React from 'react'
import styled from "styled-components"

import Link from '../utils/Link'

// STYLE
const ButtonLink = styled(Link)`
  color: var(--primary-font-color);
  padding: 0.6em 1em;
  border: var(--border-width) solid;
  border-radius: var(--border-radius);
  transition: all .3s ease-in-out;

  &.primary {
    background-color: var(--primary-btn-bg);
    border-color: var(--primary-btn-bg);
  }

  &.secondary {
    background-color: var(--secondary-btn-bg);
    border-color: var(--border-color);
    backdrop-filter: blur(4px);
  }

  &:hover, &:active {
    transition: all .3s ease-in-out;
    filter: brightness(150%);
  }
`

const StandardButton = styled.div`
  color: var(--primary-font-color);
  padding: 0.6em 1em;
  border: var(--border-width) solid;
  border-radius: var(--border-radius);
  transition: all .3s ease-in-out;

  &.primary {
    background-color: var(--primary-btn-bg);
    border-color: var(--primary-btn-bg);
  }

  &.secondary {
    background-color: var(--secondary-btn-bg);
    border-color: var(--border-color);
    backdrop-filter: blur(4px);
  }

  &:hover, &:active {
    transition: all .3s ease-in-out;
    filter: brightness(150%);
  }
`

// MARK-UP
const Button = ({ children, type, to, target, className, ...other }) => {


  // Si le bouton n'a aucune url de donnée, on utilise un bouton classique
  if (to) {
    return (
      <ButtonLink to={to} target={target} className={[type, className]} {...other}> {children} </ButtonLink>
    )
  }
  else {
    return (
      <StandardButton className={[type, className]} {...other}> {children} </StandardButton>
    )
  }
}

export default Button